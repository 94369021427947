<template>
	<section>
		<div v-if="cargandoOrden" class="card">
			<div class="card-body">
				<div class="loading loading-lg"></div>
			</div>
		</div>
		<div v-else class="card">
			<div class="card-header">
				<div class="card-title h4">Orden de Servicio #{{ orden.id }}</div>
			</div>
			<div class="card-body">
				<div class="columns">
					<div class="column col-md-12 col-4">
						<strong>Nombre del Cliente</strong>
						<p class="text-bold">
							<router-link :to="{ name: 'Cliente', params: { id: orden.cliente_id } } ">{{ orden.cliente_nombre }}</router-link>
						</p>
					</div>
					<div class="column col-md-12 col-4">
						<strong>Teléfono del Cliente</strong>
						<p>{{ orden.cliente_telefono }}</p>
					</div>
					<div class="column col-md-12 col-4">
						<strong>Dirección del Cliente</strong>
						<p>{{ orden.cliente_direccion }}</p>
					</div>
					<div class="column col-md-12 col-4">
						<strong>Creado el</strong>
						<p>{{ orden.creado }}</p>
					</div>
					<div class="column col-md-12 col-4">
						<strong>Estado</strong>
						<p>{{ orden.estado }}</p>
					</div>
					<div class="column col-md-12 col-4">
						<strong>Supervisor</strong>
						<p>{{ orden.supervisor_nombre }}</p>
					</div>
					<div class="column col-md-12 col-4">
						<strong>Técnico</strong>
						<p>{{ orden.tecnico_nombre }}</p>
					</div>
					<div class="column col-12">
						<strong>Actividades</strong>
						<div v-if="cargandoActividades">
							<div class="loading loading-lg"></div>
						</div>
						<div v-else class="timeline mt-2">
							<div v-for="(i, k) in actividades" :key="k" class="timeline-item">
								<div class="timeline-left">
									<span class="timeline-icon"></span>
								</div>
								<div class="timeline-content">
									<details class="accordion">
										<summary class="accordion-header c-hand">
											{{ i.servicio }} <i class="icon icon-caret"></i>
										</summary>
										<div class="accordion-body">
											<blockquote class="text-tiny">
												<ul>
													<li>Creado el {{ i.creado }}</li>
													<li>Materiales: {{ i.materiales }}</li>
													<li v-if="i.fotos">
														<router-link :to="{name:'Fotos', params:{id:i.id}}">Fotos: {{ i.fotos }}</router-link>
													</li>
													<li>Observaciones: {{ i.observaciones }}</li>
												</ul>
											</blockquote>
										</div>
									</details>
								</div>
							</div>
						</div>
					</div>
					<div v-if="orden.firma == 1" class="column col-12">
						<strong>Firma</strong>
						<a :href="imgFirma" target="_blank">
							<img :src="imgFirma" class="firma my-2" alt="Firma">
						</a>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'SV_Orden',
		data() {
			return {
				cargandoOrden: false,
				orden: {},
				cargandoActividades: false,
				actividades: [],
				imgFirma: ''
			}
		},
		mounted() {
			document.title = 'Cargando Orden de Servicio';
			this.infoUsuario();
			this.cargarOrden();
			this.cargarActividad();
		},
		methods: {
			infoUsuario() {
				if(Object.keys(this.$store.state.user).length === 0) {
					this.$store.state.api
						.get('usuario')
						.then(res => this.$store.commit('setUser', res.data))
						.catch(err => alert(err.response.data.mensaje));
				}
			},
			cargarOrden() {
				this.cargandoOrden = true;
				this.$store.state.api
					.get('ordenes/sv_id/' + parseInt(this.$route.params.id))
					.then(res => {
						this.orden = res.data;
						document.title = `Orden de Servicio #${res.data.id}`;
						this.imgFirma = `https://wsm.wuipi.net/api/firmas/${res.data.id}.png`;
					})
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.cargandoOrden = false);
			},
			cargarActividad() {
				this.cargandoActividades = true;
				this.$store.state.api
					.get('ordenes/actividad/' + parseInt(this.$route.params.id))
					.then(res => this.actividades = res.data)
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.cargandoActividades = false);
			},
		}
	}
</script>

<style scoped>
	.firma {
		display: block;
		height: 10rem;
		max-width: 100%;
	}
	.timeline .timeline-item:last-child::before {
		background: none;
	}
	.accordion .accordion-header {
		padding: 0;
	}
	.accordion input:checked~.accordion-header>.icon:first-child,
	.accordion[open] .accordion-header>.icon:first-child {
		transform: none;
	}
	details[open] summary ~ * {
		animation: sweep .25s ease-in-out;
	}
	@keyframes sweep {
		0%    {opacity: 0; margin-top: -15px}
		100%  {opacity: 1; margin-top: 0px}
	}
	blockquote {
		margin: 0;
		border-left: .1rem solid #e9ebf0;
	}
	ul {
		margin: 0;
	}
</style>
